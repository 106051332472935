<template>
    <div class="panel">
        <div class="header">
            维保信息
        </div>
        <div class="table">
            <div class="cell">
                <div>维保人：</div>
                <div>{{response.maintainer_name?response.maintainer_name:'--'}}</div>
            </div>
            <div class="cell">
                <div>安全管理员：</div>
                <div>{{response.safety_administrator?response.safety_administrator:'--'}}</div>
            </div>
            <div class="cell">
                <div>紧急联系电话：</div>
                <div>{{response.safety_admin_tel?response.safety_admin_tel :'--'}}</div>
            </div>
            <div class="cell">
                <div>维保单位：</div>
                <el-tooltip class="item" effect="dark" :content="response.maintenance_company_name?response.maintenance_company_name :'--'" placement="top">
                    <div>{{response.maintenance_company_name?response.maintenance_company_name :'--'}}</div>
                </el-tooltip>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            response: {}
        }
    },
    methods: {

        getInfoBy(elevatorCode, registorNum) {
            //TO-DO
        },
        updateByInfo(info) {
            let maintainers = []
            if (info.first_maintainer_name)
                maintainers.push(info.first_maintainer_name)
            if (info.second_maintainer_name)
                maintainers.push(info.second_maintainer_name)
            info.maintainer_name = maintainers.length ? maintainers.join('、') : null

            this.response = info
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/common/styles/handle.scss";

.panel {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: 1px solid rgba(255, 255, 255, 1);
    @include border_color(panelBorderColor);
    @include background_color(panelBgColor);
}

.header {
    line-height: 45px;
    height: 45px;
    font-size: 16px;
    @include font_color(singleMonitorTableHeaderTextColor);
    padding: 0 20px;
    font-weight: 600;
}

.table {
    flex: 1;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    //gap: 10px;
    justify-content: space-around;
    @include background_color(singleMonitorTableBgColor);
    @include font_color(singleMonitorTableTextColor);

    .cell {
        display: flex;
        justify-content: space-between;

        div:first-of-type {
            // font-size: 14px;
            font-weight: 600;

        }

        div:last-of-type {
            max-width: 65%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>