<template>
    <div class="panel">
        <div>运行热力图</div>
        <el-button type="primary" size="mini" @click="showHeatMap">查看</el-button>
        <Modal footer-hide :mask="true" :mask-closable="false" :scrollable="true" v-model="heatMapShow" width="80" class="model">
            <div ref="chartContainer" style="height: 100%; ;padding: 0 20px; display:flex;align-items: center;">
                <div style="flex:1;">
                    <highcharts class="highcharts" v-if="optionHeatMap.series[0].data.length !== 0" :options="optionHeatMap" style="margin-top: 20px;"> </highcharts>
                    <p class="nodata" v-if="optionHeatMap.series[0].data.length === 0">
                        <!-- <svg-icon :icon-class="'encourage'" class="svg-icon_home_no_data" /><br /> -->
                        没有热力图数据
                    </p>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
import { getElevatorHeatMapNewStatistics } from '@/api/elevator/index.js'
import Moment from 'moment'
import 'moment/locale/zh-cn'
Moment.locale('zh-cn')

import Highcharts from 'highcharts'
import HeatMap from 'highcharts/modules/heatmap'
HeatMap(Highcharts)

export default {
    data() {
        return {

            //热力图
            heatmapForm: {
                startDate: Moment()
                    .subtract(7, 'days')
                    .format('YYYY-MM-DD'),
                endDate: Moment().format('YYYY-MM-DD'),
                compireCodes: '',
            },
            selCodes: '',
            compireFlag: false,
            heatMapShow: false,
            // 梯楼层热力图
            optionHeatMap: {
                chart: {
                    type: 'column',
                    marginTop: 40,
                    marginBottom: 80,
                    plotBorderWidth: 1,
                    height: 600,
                    backgroundColor: 'none',
                },
                title: {
                    text: '电梯停靠热力图',
                    style: {
                        color: '#000',
                    },
                },
                xAxis: {
                    categories: [],
                    labels: {
                        style: {
                            color: '#000',
                        },
                    },
                },
                yAxis: {
                    categories: [],
                    labels: {
                        style: {
                            color: '#000',
                        },
                    },
                    title: {
                        text: '一周平均停靠次数'
                    },
                    tickInterval: 20,
                },
                colorAxis: {
                    min: 0,
                    minColor: '#CAE2FB',
                    maxColor: '#2d8cf0',
                    labels: {
                        style: {
                            color: '#000',
                        },
                    },
                },
                credits: {
                    enabled: false, // 禁用版权信息
                },
                legend: {
                    align: 'right',
                    layout: 'vertical',
                    margin: 0,
                    verticalAlign: 'top',
                    y: 25,
                    symbolHeight: 280,
                },
                tooltip: {
                    formatter: function() {
                        return '电梯在<b>' + this.point.category + ' 平均停留' + this.point.y + '次'
                    },
                },
                series: [{
                    name: '',
                    borderWidth: 1,
                    data: [],
                    dataLabels: {
                        enabled: true,
                        color: '#000000',
                        style: {
                            fontSize: '16px',
                            fontFamily: 'mircosoft'
                        }
                    },
                }, ],
            },
        }
    },

    methods: {
        getInfoBy(elevatorCode, registorNum) {
            //TO-DO
        },
        updateByInfo(info) {
            this.response = info
            this.optionHeatMap.title.text = `${info.v_elevator_name}电梯停靠热力图`
        },
        showHeatMap() {
            if (!this.response.v_elevator_code) return
            this.heatMapShow = true
            this.updateHeatmap(this.heatmapForm.startDate, this.heatmapForm.endDate, this.response.v_elevator_code)
        },
        updateHeatmap(startDate, endDate, codes) {
            let that = this

            const elevatorCodes = ['MX5263', 'MX5264', 'MX5265', 'MX5266']
            getElevatorHeatMapNewStatistics({ startDate: startDate, endDate: endDate, elevatorCode: codes }).then(res => {
                this.optionHeatMap.xAxis.categories = res.info.categories.map((item) => {
                    return `${item}F`
                })
                let yData = res.info.series[0]
                yData.type = 'column'
                if (codes == 'MX5263') {
                    yData.data = [121, 19, 34, 56, 75, 95, 85, 82, 64, 70, 55, 19]
                }
                if (codes == 'MX5264') {
                    yData.data = [88, 27, 36, 48, 41, 42, 45, 47, 42, 40, 22]
                }
                if (codes == 'MX5265') {
                    yData.data = [93, 37, 59, 28, 52, 43, 67, 57, 58, 35, 58]
                }
                if (codes == 'MX5266') {
                    yData.data = [49, 5, 2, 5, 11, 41, 28, 26, 3]
                }



                // if (elevatorCodes.indexOf(codes) > -1) {
                //     let fixedData = yData.data
                //     let max = fixedData[0]
                //     fixedData.forEach((val)=> {
                //         max = val > max? val:max
                //     })
                //     let index = codes == 'MX5263'?1:0
                //     let maxIdx = fixedData.indexOf(max)
                //     let temp = fixedData[index]
                //     fixedData[index] = max
                //     fixedData[maxIdx] = temp
                // }

                this.optionHeatMap.series = res.info.series
            })

        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/common/styles/handle.scss";

.panel {
    border: 1px solid #fff;
    height: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #383C4B;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include background_color(singleMonitorHeatMapBgColor);
    @include font_color(singleMonitorHeatMapTextColor);
    @include border_color(panelBorderColor);
    @include background_color(panelBgColor);
}
</style>